<template>
  <div class="add-form">
    <div class="list-title">
      一键发布
    </div>

    <el-form label-position="top" size="small">
      <el-form-item :label="$t('location')" required>
        <checkout-list :data.sync="locationList" />
        <span class="help">选择应用将要发布的集群</span>
      </el-form-item>

      <el-form-item label="YAML配置" required>
        <editor
          v-model="yamlText"
          @init="editorInit"
          lang="yaml"
          theme="cobalt"
          width="100%"
          height="400"
          style="margin-bottom: 10px"
        ></editor>
      </el-form-item>
    </el-form>

    <el-button type="primary" plain size="small" @click="submit">
      {{ $t("handle.submit") }}
    </el-button>
  </div>
</template>

<script>
import importYAMLSVG from "@/assets/illustration/import-yaml.svg";
import CheckoutList from "@/components/CheckoutList";
import { importYAML } from "api/deployment";

export default {
  components: {
    CheckoutList,
    editor: require("vue2-ace-editor")
  },

  data() {
    return {
      importYAMLSVG,
      yamlText: "",
      locationList: []
    };
  },

  computed: {
    vendor() {
      return this.locationList[0];
    },

    region() {
      return this.locationList[1];
    },

    cluster() {
      return this.locationList[2];
    }
  },

  methods: {
    editorInit(editor) {
      require("brace/ext/searchbox");
      require("brace/mode/yaml");
      require("brace/theme/cobalt");
    },

    submit() {
      if (this.locationList.length > 0) {
        importYAML({ data: this.yamlText }, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
          response => {
            console.log(response);
          }
        );
      }
    }
  }
};
</script>
